<template>
  <v-card>
    <v-row>
      <v-col
        v-for="item in listOfAspects"
        :key="item.colours"
        align-self=""
        class="ma-10 d-flex justify-center"
      >
        <div class="d-flex flex-column">
          <v-sheet
            :color="item.colour"
            outlined
            height="20"
            width="200"
          ></v-sheet>
          <div class="d-flex justify-center">
            {{ item.aspect }}
          </div>
          <div class="d-flex justify-center">
            {{ "0" }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-card-title class="align-start">
      <span>Post statistic</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <!-- Chart -->
      <vue-apex-charts
        :options="chartOptions"
        :series="chartData"
        height="210"
      ></vue-apex-charts>

      <div class="d-flex align-center">
        <h3 class="text-2xl font-weight-semibold me-4">
          45%
        </h3>
        <!-- <span>Your sales perfomance in 45% 🤩 better compare to last month</span> -->
      </div>

      <v-btn
        block
        color="primary"
        class="mt-6"
        outlined
      >
        Details
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import VueApexCharts from 'vue-apexcharts'
import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      listOfAspects: [
        { colour: '#dcf4ec', aspect: 'View' },
        { colour: '#fee0e0', aspect: 'Click' },
        { colour: '#fef5e1', aspect: 'Comment' },
        { colour: '#e9f4f6', aspect: 'Like' },
        { colour: '#e7c2ee', aspect: 'Saved' },
        { colour: '#d6d6d6', aspect: 'Shared' },
      ],
      chartOptions: {
        chart: {
          height: 500,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '50%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2'],
          },
        },
        yaxis: {},
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
    }
  },
  setup() {
    const ins = getCurrentInstance()?.proxy
    const $vuetify = ins && ins.$vuetify ? ins.$vuetify : null
    const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'
    const arrayOfColors = ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800']

    const chartData = [
      {
        data: [40, 60, 50, 60, 75, 60],
      },
    ]

    return {
      chartData,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
  computed: {
    campaignStatisticAnalyticsData() {
      return [40, 60, 50, 60, 75, 60, 50, 65]
    },
    campaignID() {
      return this.$route
    },
  },
}
</script>